import React from 'react'
import { ProductCart, toCssPrefix, Button } from '@ecommerce/shared'
import styled from 'styled-components'
import NoStockProducts from '../../NoStockProducts'
import ModalWrapper from '../../NoStockProducts/ModalWrapper'

const { cssPrefix, toPrefix } = toCssPrefix('NoStockModalBottom__')

type Props = {
  products: ProductCart[]
  isBO: boolean
  onClose: () => void
  content: {
    title: string
    subtitle: string
    buttonText: string
  }
}

const Wrapper = styled.div`
  .MyOrdersNoStockProducts {
    max-height: 300px;
  }
`

const StyledBottom = styled.div`
  .${cssPrefix} {
    &inner {
      background: ${({ theme }) => theme.colors.white};
      padding: 20px 36px;
      box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
      border-radius: ${({ theme }) => theme.borderRadius};
    }

    &button {
      min-width: 175px;
      margin-left: auto;
      display: block;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
`

const NoStockModal = ({ products, isBO, onClose, content }: Props) => {
  const Bottom = () => (
    <StyledBottom className={cssPrefix}>
      <div className={toPrefix('inner')}>
        <Button className={toPrefix('button')} onClick={onClose}>
          {content.buttonText}
        </Button>
      </div>
    </StyledBottom>
  )

  return (
    <Wrapper>
      <ModalWrapper title={content.title} subtitle={content.subtitle} bottom={<Bottom />}>
        <NoStockProducts
          className="MyOrdersNoStockProducts"
          title="Productos sin stock"
          products={products}
          isBO={isBO}
        />
      </ModalWrapper>
    </Wrapper>
  )
}

export default NoStockModal
