import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { Image, Button } from '@ecommerce/shared'

const cssPrefix = `EmptyOrdersList__`
const pfx = (className: string) => `${cssPrefix}${className}`

const commonTextProps = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Wrapper = styled.div`
  max-width: 310px;
  width: 310px;
  min-width: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 40px;
  .${cssPrefix} {
    &top-text {
      ${commonTextProps}
      font-size: 18px;
      letter-spacing: -0.29px;
      margin-bottom: 40px;
    }
    &image {
      height: 98px;
      width: 98px;
      margin-bottom: 44px;
    }
    &bottom-text {
      ${commonTextProps}
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    &button {
      width: 290px;
    }
  }
`

export interface EmptyOrdersListProps extends HTMLAttributes<HTMLDivElement> {
  topText: string[]
  imageUrl: string
  bottomText: string[]
  buttonText: string
  onButtonClick?: (e?: React.MouseEvent) => void
}

const EmptyOrdersList = ({ topText, imageUrl, bottomText, buttonText, onButtonClick }: EmptyOrdersListProps) => (
  <Wrapper>
    <div className={pfx('top-text')}>
      {topText.map((line, idx) => (
        <span key={idx.toString()} className={pfx('top-text-line')}>
          {line}
        </span>
      ))}
    </div>
    <div className={pfx('image')}>
      <Image preload={false} src={imageUrl} alt="pedidos" />
    </div>
    <div className={pfx('bottom-text')}>
      {bottomText.map((line, idx) => (
        <span key={idx.toString()} className={pfx('bottom-text-line')}>
          {line}
        </span>
      ))}
    </div>
    <Button className={pfx('button')} onClick={(e) => (onButtonClick ? onButtonClick(e) : null)}>
      {buttonText}
    </Button>
  </Wrapper>
)

export default EmptyOrdersList
